import { useNavigate } from 'react-router-dom';
import routes from '@/router/routes'

const handleRoutes = (lists) => {
  const newLists = lists.filter((item, index) => !item.hideNav)
  return newLists.map(item => {
    let navs = new Object
    if (item.meta.title) navs.label = item.meta.title
    if (item.path) navs.path = item.path
    if (item.redirect) navs.redirect = item.redirect
    if (item.name) navs.key = item.name
    return navs
  })
}

const navs = handleRoutes(routes)

const currentYear = new Date().getFullYear()

const otherNavs = [
  { label: 'News', path: '/news' },
]

const wechartPic = require('@/assets/images/wechart_pic.png')
const moreDatas = [
  { label: 'Tel:', value: '+86-755-86535323' },
  { label: 'Mob:', value: '+86 13760325759 / +86 13823785945' },
  { label: 'E-mail:', value: 'benefm@benefm.com' },
  { label: 'Headquarters:', value: "11/F, Beike Bldg., No. 59, Gaoxin South 9th Road, Nanshan District, Shenzhen, P.R.China." },
]
const datas = [
  { text: "© " + currentYear + " Shenzhen Benevolence Medical Sci&Tech Co., Ltd. All Rights Reserved." }
]

export default function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <footer className="w-full bg-[#303030] text-white text-opacity-80">
        <div className="container mx-auto py-5 xl:py-8 px-3 md:px-5 lg:px-8 xl:px-0 text-sm">
          <div className='flex flex-col xl:flex-row'>
            <div className='flex flex-col xl:flex-row w-full xl:w-1/2'>
              <div className="inline-flex flex-row flex-wrap items-start justify-center xl:justify-start w-full">
                {navs.map((item, index) => {
                  return <div
                    key={index}
                    className="xl:mb-5 xl:last:mb-0 cursor-pointer px-2 md:px-8 lg:px-10 xl:px-5 leading-6 xl:leading-none whitespace-nowrap"
                    onClick={() => item.path ? navigate(item.path) : item?.redirect ? navigate(item.redirect) : navigate('/')}
                  >
                    {item.label}
                  </div>
                })}
                {otherNavs.map((item, index) => {
                  return <div
                    key={index}
                    className="xl:mb-5 xl:last:mb-0 cursor-pointer px-2 md:px-8 lg:px-10 xl:px-5 leading-6 xl:leading-none whitespace-nowrap"
                    onClick={() => item.path ? navigate(item.path) : item?.redirect ? navigate(item.redirect) : navigate('/')}
                  >
                    {item.label}
                  </div>
                })}
              </div>
            </div>
            <div className='flex flex-col md:flex-row w-full xl:w-1/2 h-auto xl:h-32 mt-4 xl:mt-0 xl:justify-end items-start select-none'>
              <img className='h-[128px] w-[128px] mx-auto md:mr-5 mb-5 md:mb-0' src={wechartPic} alt="" />
              <div className='flex-1 text-sm flex flex-col text-white text-opacity-80'>
                {moreDatas.map((it, ix) => (
                  <div className={ix === 0 ? 'text-xs md:text-sm' : 'text-xs md:text-sm mt-3 md:mt-2'} key={ix}>
                    <label className='mr-2'>{it.label}</label>
                    <span>{it.value}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-t-1 border-t-neutral-800 py-5 text-xs text-white text-opacity-70 bg-[#212121]">
          <div className="flex flex-row justify-center md:mb-1 ">
            {datas.map((item, index) => {
              if (index < 3)
                return <div className="px-2 md:px-3 pb-2 md:pb-0" onClick={item.url ? window.open(item.url) : null} key={index}>{item.text}</div>
            })}
          </div>
          <div className="flex flex-col md:flex-row justify-center md:mb-1 md:divide-x-1 divide-white divide-opacity-30">
            {datas.map((item, index) => {
              if (index > 2)
                return <div className="px-5 md:px-3 pb-2 md:pb-0" onClick={item.url ? window.open(item.url) : null} key={index}>{item.text}</div>
            })}

          </div>
        </div>
      </footer>
    </>
  )
}