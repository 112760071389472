import Nav from './Nav';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const datas = {
  logo: require('@/assets/images/logo.png'),
  icon: 'tel',
  tel: '400-6969-772',
}

export default () => {
  const { isPc } = useSelector(state => state.device);

  const [navShow, setNavShow] = useState(false)
  const [opacity, setOpacity] = useState(0)
  const [pageW, setPageW] = useState(window.innerWidth)

  const handleShowNav = () => {
    setNavShow(!navShow)
  }

  useEffect(() => {
    window.addEventListener("resize", resizeChange)
  }, [])

  const resizeChange = () => {
    setPageW(window.innerWidth)
  }

  useEffect(() => {
    if (isPc && (pageW >= 768)) {
      setOpacity(0.6)
      if (navShow) setNavShow(false)
    }
    else setOpacity(1)
  }, [pageW])

  useEffect(() => {
    if (isPc && (pageW >= 768)) setOpacity(0.6)
    else setOpacity(1)
  }, [isPc])


  return (
    <header className={"h-12 lg:h-20 md:absolute xl:top-0 xl:left-0 xl:right-0 select-none "}>
      <div
        className="fixed flex w-full h-12 lg:h-20 z-10 text-white justify-between items-center px-5 lg:px-6"
        style={{ background: 'rgba(0,0,0, ' + opacity + ')' }}
      >
        {
          !isPc || (isPc && pageW < 768)
            ? <>
              <div onClick={handleShowNav}><MenuOutlined /></div>
              <Link className="h-5 lg:h-7 xs:flex-1 flex justify-center items-center absolute left-1/2 -ml-10"
                to={'/'}
              >
                <img src={datas.logo} alt="" className="h-full mr-5" />
              </Link>

              <a className='block md:hidden' href='https://www.benefm.com'>Chinese</a>
            </>
            : null
        }
        {
          isPc && pageW >= 768
            ? <div className="container mx-auto h-full">
              <div className="flex justify-center md:justify-between items-center h-full">
                <Link className="-mt-[10px] lg:mt-0 h-[30%]"
                  to={'/'}
                >
                  <img src={datas.logo} alt="" className="h-full" />
                </Link>
                <div className="flex-1 h-full hidden md:block">
                  <Nav pageW={pageW} />
                </div>
              </div>
            </div>
            : null
        }
      </div>

      {
        !isPc || (isPc && pageW < 768) ?
          <div className={navShow ? "fixed z-10 top-0 left-0 right-0 bottom-0" : "fixed z-10 sr-only"} >
            <div
              className="absolute bg-black bg-opacity-80 w-full h-full"
              onClick={handleShowNav}
            ></div>
            <div className={
              navShow ? 'w-4/5 bg-white h-full transform duration-500' : 'w-4/5 bg-white h-full -translate-x-full overflow-hidden'
            }>
              <div className="border-b-1 h-10 w-full mb-3 relative"><CloseOutlined className='p-3 absolute right-0' onClick={handleShowNav} /></div>
              <Nav params={navShow} hideNav={handleShowNav} pageW={pageW} />
            </div>
          </div>
          : null
      }

    </header>
  )
}