import { useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import routes from '@/router/routes';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { Divider } from 'antd-mobile';

const handleRoutes = (lists) => {
  const newLists = lists.filter((item, index) => !item.hideNav)
  return newLists.map(item => {
    let navs = new Object
    if (item.meta.title) navs.label = item.meta.title
    if (item.path) navs.path = item.path
    if (item.redirect) navs.redirect = item.redirect
    if (item.name) navs.key = item.name
    if (item.icon) navs.icon = item.icon
    if (item.children && item.children.length > 0) navs.children = handleRoutes(item.children)
    // if (item.name === "product") navs.pic = require("@/assets/images/product/nav.png")
    return navs
  })
}

const navs = handleRoutes(routes)
// const rootSubmenuKeys = datas.map(item => item.key)

export default function Nav(props) {
  const [datas, setDatas] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState();

  const { isPc } = useSelector(state => state.device);

  // const [activeNav, setActiveNav] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenNavMenu = (e) => {
    if (e?.redirect) navigate(e.redirect)
    if (isPc && props?.pageW && props.pageW >= 768) {
      if (e?.path && e.path.match(":") && e?.key)
        return navigate(e.path.split(":")[0] + e.key)
      else if (e?.path && !e.path.match(":"))
        return navigate(e.path)
    } else {
      if (e?.keyPath && e.keyPath.length > 1 && e?.key) {
        navigate('/' + e.keyPath.reverse().join('/'))
      } else if (e?.keyPath && e.keyPath.length === 1) {
        navigate(e.keyPath[0])
      }
      props.hideNav()
      return
    }
    // if (!e.key && !e.redirect && !e.keyPath) return;
    // else if (e.keyPath && e.keyPath.length > 1 && e.item.props.path) navigate(e.item.props.path)
    // else if (e.keyPath && e.keyPath.length > 1 && e.item.props.redirect) navigate(e.item.props.redirect)
    // else if (e.key) navigate(e.key)
    // else if (e.redirect) navigate(e.redirect)
    // if (props.params) {
    //   props.hideNav()
    // }
  }



  const handle = () => {
    navs.map(item => {
      if (item.children && item.children.length > 0) {
        if ((isPc && props?.pageW && (props.pageW < 768)) || !isPc)
          item.children.unshift({
            label: item.label,
            path: item.path,
            key: 'product_back',
          })
        else if (item.children.length > 3) item.children.shift()
        item.children = [...new Set(item.children)]
      }
      if (item.key === "product") item.pic = require("@/assets/images/product/nav.png")
    })
    setDatas(navs)
  }

  useEffect(() => {
    if (navs.length > 0) {
      handle()
    }
  }, [])

  useEffect(() => {
    if (props?.pageW) {
      if (datas.length > 0 && isPc && (props.pageW === 767 || props.pageW === 768)) {
        handle()
      }
    }
  }, [props?.pageW])

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    let arr = location.pathname.split('/').filter((it) => it)
    setSelectedKeys(arr);
    if (arr.length > 1) {
      arr.unshift()
      setOpenKeys(arr);
    } else {
      setOpenKeys();
    }
  }, [location.pathname])

  useEffect(() => {
    if (selectedKeys) {
      if (selectedKeys[0] === 'product' && selectedKeys.length === 1 && !isPc) {
        selectedKeys.push('product_back')
        setOpenKeys(selectedKeys)
      }
    }
  }, [selectedKeys])


  return (
    <>
      {isPc ?
        <nav className="flex flex-col w-full md:flex-row items-start md:items-center h-full md:justify-end mr-0 md:mr-40">
          {datas.length > 0 ? <>
            {
              datas.map((item, index) => (
                <div className="w-full md:w-auto md:h-full" key={index}>
                  <div className="hidden h-full md:block group">
                    <button
                      onClick={() => handleOpenNavMenu(item)}
                      className={
                        (location.pathname.includes(item.path) && item.path !== '/') ||
                          ((location.pathname === '/' || location.pathname.includes('hardware') || location.pathname.includes('software') || location.pathname.includes('videos') || location.pathname.includes('news')) && item.path === '/') ||
                          (location.pathname.includes('recruitment') && item.path.includes('job')) ?
                          'text-orange-400 px-8 md:px-5 lg:px-6 xl:px-10 py-3 md:py-0 md:h-full focus:outline-none lg:text-lg' :
                          'px-8 md:px-5 lg:px-6 xl:px-10 py-3 md:py-0 md:h-full focus:outline-none lg:text-lg hover:text-orange-400'
                      }
                    >
                      {item.label}
                    </button>
                    {
                      item?.children && item.children.length > 0 ?
                        <div className="overflow-hidden absolute left-0 right-0 shadow-md">
                          <div className="bg-white text-black w-full h-0 group-hover:h-auto group-hover:animate-ttb animation-count-1 ">
                            <div className="container mx-auto">
                              <div className="py-8 flex">
                                {item?.pic ? <img src={item.pic} className="float-left" /> : null}
                                <Divider direction="vertical h-auto mx-14" />
                                <ul className="select-none m-0">
                                  {item.children.map((it, ix) =>
                                    <li
                                      className={`border-b-1 pr-20 py-3 leading-normal ${((location.pathname.includes(it?.path) || location.pathname.includes(it?.key)) && it.path) ? 'text-orange-500'
                                        : 'hover:text-orange-500 cursor-pointer'}`} // whitespace-nowrap
                                      key={ix}
                                      onClick={() => handleOpenNavMenu(it)}
                                    >
                                      {it.label}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        : null
                    }
                  </div>
                </div>
              ))
            }

            <a className='hidden md:block' href='https://www.benefm.com'>Chinese</a>
            <div className="md:hidden w-full">
              {
                selectedKeys ?
                  <Menu
                    mode='inline'
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    onClick={handleOpenNavMenu}
                    selectedKeys={selectedKeys}
                    items={datas}
                  />
                  : null
              }
            </div>
          </>
            : null}
        </nav>
        :
        <nav className="flex flex-col w-full items-start h-full mr-0">
          <div className="w-full">
            {
              selectedKeys ?
                <Menu
                  mode='inline'
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  onClick={handleOpenNavMenu}
                  selectedKeys={selectedKeys}
                  items={datas}
                />
                : null
            }
          </div>
        </nav>
      }
    </>
  )
}