import { lazy } from 'react';
import { HomeOutlined, AppstoreOutlined, DeploymentUnitOutlined, ApartmentOutlined, TeamOutlined } from '@ant-design/icons';

export default [
  { path: '/', hideNav: true, component: lazy(() => import('@/pages/Home')) },
  {
    path: '/home', name: 'home', icon: <HomeOutlined />, meta: { title: "Home" },
    component: lazy(() => import('@/pages/Home'))
  },
  {
    path: '/product', name: 'product', icon: <AppstoreOutlined />, meta: { title: "Products and Services" },
    component: lazy(() => import('@/pages/Product')),
    children: [
      { path: '/product/:id', name: 'Benefm-Intelligent-ECG-Suits', meta: { title: "Benefm Intelligent ECG Suits" }, component: lazy(() => import('@/pages/Product/details')) },
      { path: '/product/:id', name: 'Holter', meta: { title: "Holter" }, component: lazy(() => import('@/pages/Product/details')) },
      { path: '/product/:id', name: 'Benecare-ECG-Patch', meta: { title: "Benecare ECG Patch" }, component: lazy(() => import('@/pages/Product/details')) }
    ]
  },
  {
    path: '/apply', name: 'apply', icon: <DeploymentUnitOutlined />, meta: { title: "Applications" },
    component: lazy(() => import('@/pages/Apply'))
  },
  {
    path: '/about', name: 'about', icon: <TeamOutlined />, meta: { title: "About us" },
    component: lazy(() => import('@/pages/About'))
  },
  { path: '/recruitment/:type', hideNav: true, meta: { title: "Recruitment" }, component: lazy(() => import('@/pages/Recruitment')) },
  { path: '/videos', hideNav: true, meta: { title: "Videos" }, component: lazy(() => import('@/pages/Videos')) },
  { path: '/videos/:id', hideNav: true, meta: { title: "Videos Details" }, component: lazy(() => import('@/pages/Videos/details')) },
  { path: '/news', hideNav: true, meta: { title: "News" }, component: lazy(() => import('@/pages/News')) },
  { path: '/news/:id', hideNav: true, meta: { title: "News Details" }, component: lazy(() => import('@/pages/News/details')) },
  { path: '/download', hideNav: true, meta: { title: "Downloads" }, component: lazy(() => import('@/pages/Download')) },
  { path: '/qa', hideNav: true, meta: { title: "Question And Answer" }, component: lazy(() => import('@/pages/Qa')) },
  { path: '/search', hideNav: true, meta: { title: "Search" }, component: lazy(() => import('@/pages/Search')) },
  { path: '*', hideNav: true, component: lazy(() => import('@/pages/Error')) }
]