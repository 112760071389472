import React from 'react'
import ReactDOM from 'react-dom/client'
import { Fragment } from 'react'
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store'
import App from './App'
import { ConfigProvider } from 'antd';

import zh_CN from 'antd/es/locale/zh_CN';
import en_US from 'antd/es/locale/en_US';
import './index.scss'
import 'antd/dist/antd.less';
import './assets/styles/base.scss'
import './assets/icons';  // 引入svg

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <Provider store={store}>
    <Fragment>
      <HashRouter>
        <ConfigProvider locale={en_US}>
          <App />
        </ConfigProvider>
      </HashRouter>
    </Fragment>
  </Provider>
  // </React.StrictMode>
);
