import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const pageView = () => {
  return <>
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white z-50 flex justify-center items-center">
      <Spin indicator={antIcon} tip="Loading..." />
    </div>
  </>
}

export default pageView