import TYPES from '../types'

const init = {
  isPc: true,
}

export default (state = init, action) => {
  const { isPc } = action.payload || {};

  switch (action.type) {
    case TYPES.DEVICE_IS_PC:
      return {
        ...state,
        isPc,
      };
    default:
      return state;
  }
}