import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import routes from '@/router/routes';
import { Router } from './router'
import { useEffect } from 'react';
import actions from './action';
import Header from '@/pages/Layout/Header'
import Footer from '@/pages/Layout/Footer'


function handleRouter(routers) {
  let arr = []
  routers.map(item => {
    if (item?.component) {
      arr.push(item)
    }
    if (item?.children && item.children.length > 0) {
      return handleRouter(item.children)
    }
  })
  return arr
}

// 路由处理方式
const generateRouter = routers => {
  let arr1 = []
  let arr2 = []
  routers.map(item => {
    if (item?.component) {
      arr1.push(item)
    }
    if (item?.children && item.children.length > 0) {
      arr2 = ([...handleRouter(item.children)])
    }
  })

  return [...arr2, ...arr1]
}

const datas = generateRouter(routes)

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const deviceIsPC = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      // 移动端适配
      // console.log('移动端适配');
      dispatch(actions.device.typeIsPc(false))
    } else {
      // PC端适配
      // console.log('PC端适配');
      dispatch(actions.device.typeIsPc(true))
    }
  }

  useEffect(() => {
    deviceIsPC()
    window.addEventListener('resize', deviceIsPC)
    return () => {
      window.removeEventListener('resize', deviceIsPC)
    }
  }, [])

  useEffect(() => {
    // console.log(location, datas)
    if (datas && datas.length > 0 && location.pathname) {
      datas.map(it => {
        if (it?.path && it.path === location.pathname && it?.meta?.title) {
          document.title = it.meta.title + " - Benefm"
        } else if (it?.path && it?.name) {
          if (it.path.split(':').length > 1) {
            if ((it.path.split(':')[0].split('/')[1] === location.pathname.substring(1, location.pathname.length).split('/')[0])
              && it.name === location.pathname.substring(1, location.pathname.length).split('/')[1]
            ) {
              document.title = it.meta.title + " - Benefm"
            }
          }
        }
      })
    }
  }, [location])

  return (<>
    <Header />
    <Router />
    <Footer />
  </>
  )
}

export default App
