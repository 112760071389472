import MyProgress from '@/components/MyProgress';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';

// 获取路由
const checkAuth = (routers, path) => {
  for (const data of routers) {
    if (data.path === path) return data
    if (data.children) {
      const res = checkAuth(data.children, path)
      if (res) return res
    }
  }
  return null
}

function handleRouter(routers) {
  let arr = []
  routers.map(item => {
    if (item?.component) {
      item.element = <Suspense fallback={<MyProgress />}><item.component /></Suspense>
      arr.push(item)
    }
    if (item?.children && item.children.length > 0) {
      return handleRouter(item.children)
    }
  })
  return arr
}

// 路由处理方式
const generateRouter = routers => {
  let arr1 = []
  let arr2 = []
  routers.map(item => {
    if (item?.component) {
      item.element = <Suspense fallback={<MyProgress />}><item.component /></Suspense>
      arr1.push(item)
    }
    if (item?.children && item.children.length > 0) {
      arr2 = ([...handleRouter(item.children)])
    }
  })

  return [...arr2, ...arr1]
}

const Router = () => useRoutes(generateRouter(routes))

const checkRouterAuth = path => {
  let auth = null
  auth = checkAuth(routes, path)
  return auth
}

export { Router, checkRouterAuth }